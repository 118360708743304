import React from "react";  
import './Navbar.css';
import { Link } from "react-router-dom";




const Navbar = () => {
    return (
        <div className="navbody">
            <nav className="nav">
                <ul>
                    <li ><Link className="link" to="/">Home</Link></li>
                    <li className="hideOnMobile" style={{border: '2px solid rgba(255, 255, 255, 0.5)', backgroundColor: 'black'}}><Link to="/stock-analytics">Playground</Link></li>
                    <li className="hideOnMobile" style={{border: '2px solid rgba(255, 255, 255, 0.5)', backgroundColor: 'black'}}><Link to="/stock-analytics">Stocks Analytics</Link></li>
                    <li className="hideOnMobile" style={{border: '2px solid rgba(255, 255, 255, 0.5)', backgroundColor: 'black'}}><Link to="/stock-analytics">Hire Me</Link></li>
                </ul>
            </nav>
        </div>
    );
}


export default Navbar;

