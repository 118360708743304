import React from 'react'
import "./StockAnalytics.css"
import MainChart from './MainChart'

function StockAnalytics() {
  return (
    <div className='Mainbody'>
        <div className='Livemainbody'>
            <h1>Live body</h1>

        </div>
        <div className='Btmainbody'>
            <div className='Selector'>
                <h1>Selector</h1>
            </div>
            <div className='Chart'>
                <div className='Chartbody'>
                    <MainChart />
                </div>
                <div className='Statsbody'>
                    <h1>Statsbody</h1>
                </div>
                <div className='Topgainer'>
                    <h1>Topgainer</h1>
                </div>
            </div>
            <div className='Extra'>
                <h1>Extra</h1>
            </div>

        </div>
      
    </div>
  )
}

export default StockAnalytics
