import React from 'react';
import MainCard from './Components/Cards/MainCard'; 
import SkillCard from './Components/Skills/SkillCard';
import About from './Components/About/About';
import Footer from './Components/Footer/Footer';

const Home = () => {
    return (
        <div>
            <MainCard />
            <About />
            <SkillCard />
            <MainCard />
            <MainCard />
            <Footer />
        </div>
    );
}

export default Home;
