import React from "react";  
import './SkillCard.css';
import { RiShieldStarFill } from "react-icons/ri";
import { FaPython } from "react-icons/fa";
import { FaReact } from "react-icons/fa";
import { SiSqlite } from "react-icons/si";
import { FaChartLine } from "react-icons/fa";
import { LuBrainCircuit } from "react-icons/lu";
import { VscTerminalLinux } from "react-icons/vsc";






const SkillCard = () => {
    return (
        <div className="mainskillcardbody">
            <div className="skills_heading" style={{ textAlign: 'center' }}> 
                <div className="skilltextbody">
                    <h1> Skills </h1>
                    <p style={{lineHeight: '1.2'}}> 
                        Alothough I have experties in Financial technologies but here is a small glims of my skills that I have aquired so far and I am working on. 
                    </p>
                </div>
            </div>
            <div className="childskillcardbody" >
                <div className="singleskillcardbody">
                    <div className="cardlogo">
                        <FaChartLine size={200}/>
                    </div>
                    <div className="cardname">
                        <h2>FINTECH</h2>
                    </div>
                    <div className="cardstar">
                    <RiShieldStarFill size={40} />
                    <RiShieldStarFill size={40} />
                    <RiShieldStarFill size={40} />
                    <RiShieldStarFill size={40} />
                    <RiShieldStarFill size={40} />                      
                    </div>
                    <div className="cardafterhover">
                        <h2>Financial Technologies</h2>
                        <p>I aquired many knowledge in python for data analysis</p>
                        <button style={{color: 'white', border: '1px solid white'}} className="btn">Go to Projects</button>
                    </div>
                </div>
                <div className="singleskillcardbody">
                    <div className="cardlogo">
                        <FaPython size={200}/>
                    </div>
                    <div className="cardname">
                        <h2>PYTHON</h2>
                    </div>
                    <div className="cardstar">
                    <RiShieldStarFill size={40} />
                    <RiShieldStarFill size={40} />
                    <RiShieldStarFill size={40} />
                    <RiShieldStarFill size={40} />
                    <RiShieldStarFill size={40} />                      
                    </div>
                    <div className="cardafterhover">
                        <h2>Python</h2>
                        <p>I aquired many knowledge in python for data analysis</p>
                        <button style={{color: 'white', border: '1px solid white'}} className="btn">Go to Projects</button>
                    </div>
                </div>
                <div className="singleskillcardbody">
                    <div className="cardlogo">
                        <FaReact size={200}/>
                    </div>
                    <div className="cardname">
                        <h2>REACT.JS</h2>
                    </div>
                    <div className="cardstar">
                    <RiShieldStarFill size={40} />
                    <RiShieldStarFill size={40} />
                    <RiShieldStarFill size={40} />
                      
                    </div>
                    <div className="cardafterhover">
                        <h1>React.js</h1>
                        <p>I have built many prijects using React.js which leads me to learn many web development skills and tools like html, htmx, css, javascript and many more.
                            This website is built using all those skills.
                        </p>
                        <button style={{color: 'white', border: '1px solid white'}} className="btn">Go to Projects</button>
                    </div>
                </div>

                <div className="singleskillcardbody">
                    <div className="cardlogo">
                        <SiSqlite size={200}/>
                    </div>
                    <div className="cardname">
                        <h2>SQLITE3</h2>
                    </div>
                    <div className="cardstar">
                    <RiShieldStarFill size={40} />
                    <RiShieldStarFill size={40} />
                    <RiShieldStarFill size={40} />
                    <RiShieldStarFill size={40} />
                    </div>
                    <div className="cardafterhover">
                        <h2>SQL Database</h2>
                        <p>I aquired many knowledge in python for data analysis</p>
                        <button style={{color: 'white', border: '1px solid white'}} className="btn">Go to Projects</button>
                    </div>
                </div>

                <div className="singleskillcardbody">
                    <div className="cardlogo">
                        <LuBrainCircuit size={200}/>
                    </div>
                    <div className="cardname">
                        <h2>DATA SCIENCE</h2>
                    </div>
                    <div className="cardstar">
                    <RiShieldStarFill size={40} />
                    <RiShieldStarFill size={40} /> 
                    <RiShieldStarFill size={40} />                     
                    </div>
                    <div className="cardafterhover">
                        <h2>Data Analytics</h2>
                        <p>I aquired many knowledge in python for data analysis</p>
                        <button style={{color: 'white', border: '1px solid white'}} className="btn">Go to Projects</button>
                    </div>
                </div>
                <div className="singleskillcardbody">
                    <div className="cardlogo">
                        <VscTerminalLinux size={200}/>
                    </div>
                    <div className="cardname">
                        <h2>LINUX</h2>
                    </div>
                    <div className="cardstar">
                    <RiShieldStarFill size={40} />
                    <RiShieldStarFill size={40} />
                    <RiShieldStarFill size={40} />
                    <RiShieldStarFill size={40} />                    
                    </div>
                    <div className="cardafterhover">
                        <h2>Linux Commands</h2>
                        <p>I aquired many knowledge in python for data analysis</p>
                        <button style={{color: 'white', border: '1px solid white'}} className="btn">Go to Projects</button>
                    </div>
                </div>
                <div className="singleskillcardbody">
                    <div className="cardlogo">
                        <FaPython size={200}/>
                    </div>
                    <div className="cardstar">
                    <RiShieldStarFill size={40} />
                    <RiShieldStarFill size={40} />
                    <RiShieldStarFill size={40} />                     
                    </div>
                    <div className="cardafterhover">
                        <h2>Python</h2>
                        <p>I aquired many knowledge in python for data analysis</p>
                        <button style={{color: 'white', border: '1px solid white'}} className="btn">Go to Projects</button>
                    </div>
                </div>
                <div className="singleskillcardbody">
                    <div className="cardlogo">
                        <FaPython size={200}/>
                    </div>
                    <div className="cardstar">
                    <RiShieldStarFill size={40} />
                    <RiShieldStarFill size={40} />
                    <RiShieldStarFill size={40} />
                    <RiShieldStarFill size={40} />                     
                    </div>
                    <div className="cardafterhover">
                        <h2>Python</h2>
                        <p>I aquired many knowledge in python for data analysis</p>
                        <button style={{color: 'white', border: '1px solid white'}} className="btn">Go to Projects</button>
                    </div>
                </div>
             

            </div>
        </div>
    );
}



export default SkillCard;

