import React from 'react'
import "./MainChart.css"


const MainChart = () => {
  return (
    <div className='MainChart'>
        <h1>MainChart</h1>
      
    </div>
  )
}

export default MainChart
