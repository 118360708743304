import React from 'react'
import './Footer.css';

function Footer() {
  return (
    <div className='mainfooterbody'>
        <div className='helpcard' >
            <div className='helpcardtext' >
                <h1>Helpcard</h1>
            </div>
            <div className='helpcardbutton' >
            <h1>button</h1>
            </div>
        </div>
        <div className='footer' >
            <h1>Footer</h1>
        </div>
    </div>
  )
}

export default Footer
