import React from "react";
import './About.css';



const About = () => {
    return (
        <div className="aboutmainbody">
            <div className="aboutheadingbody">
                <h1> About Me </h1>
            </div>
            <div className="mainTextbody">
                <div className="descriptiontext">
                    <p> Hello! I'm Mohd Sharjeel, a passionate in Financial Machine Learning and Financial Technologies. My journey into the fascinating world of finance and technology began with a curiosity about the intricate mechanisms that drive markets and an eagerness to leverage technology to innovate and solve real-world problems.
                        <br/>
                        I have done my Bachelors in International Financial and accounting from Graphic Era Hill Univercity.
                        Currently, I am pursuing my studies in [Your University/Institution], where I delve into the intricacies of financial data analysis, algorithmic trading, and machine learning applications in finance. Through rigorous coursework and hands-on projects, I have honed my skills in various programming languages, data analysis techniques, and machine learning algorithms, all aimed at making informed and strategic financial decisions.<br/>
                        <br/>
                        My areas of expertise include:
                        <br/>
                        Financial Data Analysis: Extracting valuable insights from complex datasets to support data-driven decision-making.
                        <br/>
                        Algorithmic Trading: Developing and backtesting trading strategies to optimize performance and manage risks.
                        <br/>
                        Machine Learning: Applying advanced machine learning techniques to predict market trends, assess risks, and improve investment strategies.
                        <br/>
                        Financial Technologies: Exploring innovative technologies such as blockchain, cryptocurrencies, and fintech applications.
                        <br/>
                        <br/>
                        Beyond academics, I am constantly exploring the latest trends in the financial industry and experimenting with new technologies to stay ahead of the curve. I believe in the power of continuous learning and am driven by a desire to contribute to the evolving landscape of financial technologies.
                        <br/>
                        When I'm not immersed in my studies or coding up new projects, you can find me [insert hobbies or interests here, e.g., reading about financial markets, participating in fintech hackathons, or enjoying a good game of chess].
                        <br/>
                        Feel free to explore my portfolio to see the projects I've worked on and the skills I've developed. Let's connect and collaborate on exciting new ventures in the world of finance and technology!
                    </p>                     
                </div>
                {/* <div className="experience">
                    <p> I am a student of Computer Science and Engineering.<br/>
                    s of web development and backend development.
                    </p>                     
                </div> */}
                
  
            </div> 
        </div>
       
    );
}

export default About;
