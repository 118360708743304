import React from "react";
import './Card.css';
import { FaLinkedin } from "react-icons/fa";
import { FaSquareGithub } from "react-icons/fa6";





const MainCard = () => {
    return (


        <div className="maincardbody">
            <div className="cardbody">



                <div className="upper">

                        <div >
                            <div className="text" style={{ lineHeight: '1.5' }}>
                                <div>
                                    <p style={{ fontSize: 'clamp(8px, 1vw, 10px)', textAlign: 'center'}}>Welcome, here's a little glimpse of my work</p>
                                </div>
                                <div  className='myname' >
                                    <h1 style={{ fontSize: 'clamp(55px, 8vw, 65px)' }}>Mohd Sharjeel</h1>
                                </div>
                                <div>
                                    <p style={{ fontWeight: 'bold', fontSize: 'clamp(14px, 2vw, 18px)' }}>Financial Trading Algorithm Designer</p>
                                    <p style={{ fontSize: 'clamp(10px, 2vw, 15px)'}}>
                                    Detail-oriented team player with strong organizational skills. <br/>
                                    Ability to handle multiple projects simultaneously with a high degree of accuracy.
                                    </p>

                                </div>
                            </div>
                        </div>

                        <div className="mypic" >
                            <img src='./mypic.png' alt="Avatar" style={{width: '310px', maxWidth: '100%'}} />
                        </div>
                        
                </div>





                <div className="lower">
                    <div>
                    <FaLinkedin size={40} />
                    </div>
                    <div>
                    <FaSquareGithub size={40} />
                    </div>

                </div>
            </div>
            

        </div>



    );
}

export default MainCard;

